@import 'react-redux-toastr/src/styles/index';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

html,
body {
  height: 100%;
}

body {
  font-family: Tahoma;
  font-size: 12px;
  color: #000;
  margin: 0 auto;
}

button,
select,
input,
textarea {
  font-family: Tahoma;
  color: #000;
  font-size: 12px;
}

// Cтилизация подсказки авторизации
#__next .redux-toastr .toastr.rrt-success {
  background-color: #fdf2d0;
}

#__next .redux-toastr .top-right {
  width: 270px !important;
}

#__next .redux-toastr .toastr .close-toastr {
  color: #714444;
  font-weight: bold;
  font-size: 20px;
}

#__next .redux-toastr .toastr .close-toastr span {
  right: 5px;
  top: 20%;
}

#__next .redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: #654109;
  margin-top: 0;
}

#__next .redux-toastr .toastr .rrt-progress-container {
  background: #eed4a4;
  margin: 0 -20px -20px 0px;
}

#__next .redux-toastr .toastr.rrt-success .rrt-progressbar {
  background: #d1b072;
}

#__next .redux-toastr .toastr .rrt-middle-container {
  font-size: 14px;
  color: #684813;
  padding: 10px 0px;
}

#__next .redux-toastr .toastr .rrt-middle-container .rrt-title {
  font-size: 14px;
  font-weight: bold;
  color: #654109;
}

// Ошибка
#__next .redux-toastr .toastr.rrt-error {
  background-color: #fdf2d0;
}

#__next .redux-toastr .toastr.rrt-error .rrt-left-container .rrt-holder {
  top: 52%;
  margin-top: -35px;
  left: 0px;
}

#__next .redux-toastr .toastr.rrt-error .rrt-middle-container {
  padding: 21px 0px;
  margin-left: 56px;
}

#__next .redux-toastr .toastr.rrt-error .rrt-progressbar {
  background: #d1b072;
}

//Литрес виджет - фикс ширины
#widget {
  .litres_partner__widget {
    min-width: auto !important;
  }
}

//Cтилизация аудиоплеера
audio::-webkit-media-controls-panel {
  background-color: white;
  border: 1px solid #dadada !important;
  box-shadow: 10px 5px 5px red;
}

//Стилизация скроллбара
/*Стилизация скроллбара*/
/* Firefox */
@-moz-document url-prefix() {
  #__next div[data-scrollbar='styles'] {
    scrollbar-width: thin;
    scrollbar-color: rgba(178, 161, 113, 0.47) #f1e4be;
  }
}

/* Chrome, Edge и Safari */
#__next div[data-scrollbar='styles']::-webkit-scrollbar {
  height: 8px;
}

#__next div[data-scrollbar='styles']::-webkit-scrollbar-track {
  background: #f1e4be;
  border-radius: 4px;
}

#__next div[data-scrollbar='styles']::-webkit-scrollbar-thumb {
  background-color: rgba(178, 161, 113, 0.47);
  border-radius: 20px;
}

#__next #nprogress .bar {
  border-bottom: 2px solid rgba(178, 161, 113, 0.47);
}
